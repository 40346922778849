<template>
  <v-form class="my-8">
    <div v-if="isLoggedIn" class="mx-2">
      <p>
        {{
          $t("forms.eventRegistration.welcome", {
            user: `${this.user.firstname} ${this.user.lastname}`
          })
        }}
      </p>
      <a href="#" @click="logout">{{
        $t("forms.eventRegistration.notUser", { user: this.user.firstname })
      }}</a>
    </div>

    <div v-else>
      <v-expansion-panels v-model="accordion" accordion mandatory hover flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="elevation-1">
            {{ $t("forms.eventRegistration.login") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <FormLogin ref="login" :error="loginError" :showButton="false" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="elevation-1">
            {{ $t("forms.eventRegistration.signUp") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <FormUser
              ref="signUp"
              :error="signUpError"
              :showButton="false"
              :passwordRequired="true"
              :showConsent="true"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-form>
</template>

<script>
/* eslint-disable */

import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import FormLogin from "@/components/FormLogin";
import FormUser from "@/components/FormUser";

export default {
  name: "FormCheckIn",
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  components: {
    FormLogin,
    FormUser
  },
  data() {
    return {
      accordion: null,
      login: {
        error: null
      },
      signUp: {
        error: null
      }
    };
  },
  validations: {
    user: { required }
  },
  computed: {
    isLoggedIn() {
      return this.user ? true : false;
    },
    loginError() {
      return this.$i18n.t(this.login.error);
    },
    signUpError() {
      return this.$i18n.t(this.signUp.error);
    }
  },
  methods: {
    ...mapActions({
      logoutUser: "user/logoutUser",
      authenticateUser: "user/authenticateUser",
      createUser: "user/createUser"
    }),
    submit() {
      this.$v.$touch();

      return new Promise((resolve, reject) => {
        if (!this.$v.$invalid) {
          resolve();
        }

        let auth;
        if (this.accordion === 0) {
          auth = this.$refs.login.submit();
        } else if (this.accordion === 1) {
          auth = this.$refs.signUp.submit();
        }

        auth
          .then(form => {
            if (this.accordion === 0) {
              this.loginSubmit(form)
                .then(() => {
                  if (!this.$v.$invalid) {
                    resolve();
                  } else {
                    reject("Form check-in invalid");
                  }
                })
                .catch(error => {
                  reject(error);
                });
            } else if (this.accordion === 1) {
              this.signUpSubmit(form)
                .then(() => {
                  if (!this.$v.$invalid) {
                    resolve();
                  } else {
                    reject("Form check-in invalid");
                  }
                })
                .catch(error => {
                  reject(error);
                });
            }
          })
          .catch(error => reject(error));
      });
    },
    logout() {
      this.login.error = null;
      this.signUp.error = null;

      this.logoutUser();
    },
    loginSubmit(form) {
      return new Promise((resolve, reject) => {
        this.login.error = null;

        this.authenticateUser({
          username: form.email,
          password: form.password
        })
          .then(() => {
            resolve();
          })
          .catch(response => {
            switch (response.data.status) {
              case -1000:
                this.login.error = "forms.login.errors.wrongCredentials";
                break;
              default:
                this.login.error = "errors.unexpectedError";
            }

            reject();
          });
      });
    },
    signUpSubmit(form) {
      return new Promise((resolve, reject) => {
        this.signUp.error = null;

        this.createUser(form)
          .then(() => {
            resolve();
          })
          .catch(response => {
            switch (response.data.status) {
              case -1002:
                this.signUp.error = "forms.signUp.errors.existingEmail";
                break;
              default:
                this.signUp.error = "errors.unexpectedError";
            }

            reject();
          });
      });
    }
  }
};
</script>
