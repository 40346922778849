<template>
  <v-container class="my-4">
    <p class="headline success--text">
      {{ $t("forms.eventRegistration.successTitle") }}
    </p>
    <p>
      {{ $t("forms.eventRegistration.success", { event: event.name }) }}
      <i18n path="forms.eventRegistration.home">
        <template v-slot:action>
          <router-link :to="{ name: 'Home' }" exact>{{
            $t("forms.eventRegistration.action")
          }}</router-link>
        </template>
      </i18n>
    </p>
  </v-container>
</template>

<script>
export default {
  name: "FormSuccess",
  props: {
    event: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  }
};
</script>
