<template>
  <v-form class="pa-4">
    <v-progress-linear
      :value="progress"
      :color="complete ? 'success' : 'primary'"
      class="mt-4"
      height="6"
      rounded
    ></v-progress-linear>

    <keep-alive>
      <component
        v-if="!complete"
        ref="currentStep"
        :is="currentStep"
        :wizard-data="form"
        :event="event"
        :user="user"
      ></component>
    </keep-alive>

    <FormSuccess v-if="complete" :event="event" :user="user"></FormSuccess>

    <v-alert v-if="error" class="mt-0 mb-6" dense outlined type="error">
      {{ $t(error) }}
    </v-alert>

    <div v-if="!complete" class="d-flex justify-space-between">
      <v-btn @click="backButtonAction">
        {{
          isFirstStep
            ? $t("forms.eventRegistration.cancel")
            : $t("forms.eventRegistration.back")
        }}
      </v-btn>

      <v-btn :loading="loading" class="primary" @click="nextButtonAction">
        {{
          isLastStep
            ? $t("forms.eventRegistration.submit")
            : $t("forms.eventRegistration.next")
        }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
/* eslint-disable */

import { mapActions } from "vuex";
import FormCheckIn from "@/components/forms/registration/FormCheckIn";
import FormFields from "@/components/forms/registration/FormFields";
import FormReview from "@/components/forms/registration/FormReview";
import FormSuccess from "@/components/forms/registration/FormSuccess";
import whitelist from "@/mixins/whitelist";

export default {
  name: "FormWizard",
  props: {
    event: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      default: null
    }
  },
  components: {
    FormCheckIn,
    FormFields,
    FormReview,
    FormSuccess
  },
  data() {
    return {
      form: {
        fields: null,
        consent: false
      },
      error: null,
      loading: false,
      currentStepNumber: 1,
      complete: false,
      steps: ["FormCheckIn", "FormFields", "FormReview"]
    };
  },
  computed: {
    currentStep() {
      return this.steps[this.currentStepNumber - 1];
    },
    progress() {
      return (this.currentStepNumber / this.steps.length) * 100;
    },
    isFirstStep() {
      return this.currentStepNumber === 1;
    },
    isLastStep() {
      return this.currentStepNumber === this.steps.length;
    },
    basicFields() {
      return this.form.fields.filter(el => this.whitelist.includes(el.id));
    },
    customFields() {
      return this.form.fields.slice(
        this.basicFields.length,
        this.form.fields.length
      );
    }
  },
  watch: {
    user(newUser, oldUser) {
      if (newUser) {
        this.setRegistrationFields();
      }
    }
  },
  mixins: [whitelist],
  methods: {
    ...mapActions({
      registerUserToEvent: "user/registerUserToEvent"
    }),
    backButtonAction() {
      if (this.isFirstStep) {
        this.$router.push({ name: "Home" });
      } else {
        this.currentStepNumber -= 1;
      }
    },
    nextButtonAction() {
      this.$refs.currentStep
        .submit()
        .then(stepData => {
          Object.assign(this.form, stepData);

          if (this.isLastStep) {
            this.submitForm();
          } else {
            this.currentStepNumber += 1;
          }
        })
        .catch(error => console.log(error));
    },
    submitForm() {
      this.error = null;
      this.loading = true;

      const form = {
        startNumber: this.form.fields[0] ? this.form.fields[0].value : null,
        userData: {
          lastName: this.form.fields[1] ? this.form.fields[1].value : null,
          firstName: this.form.fields[2] ? this.form.fields[2].value : null,
          gender:
            this.form.fields[3] && this.form.fields[3].value
              ? this.form.fields[3].value.toUpperCase()
              : null,
          dateOfBirth: this.form.fields[4].value,
          nation: this.form.fields[5] ? this.form.fields[5].value : null,
          club: this.form.fields[6] ? this.form.fields[6].value : null,
          team: this.form.fields[7] ? this.form.fields[7].value : null,
          class: this.form.fields[8] ? this.form.fields[8].value : null,
          email: this.form.fields[9] ? this.form.fields[9].value : null
        }
      };

      this.customFields.map((field, index) => {
        form.userData[field.id] = field.value;
      });

      const payload = {
        eventId: this.event.id,
        userId: this.user.id,
        data: form
      };

      this.registerUserToEvent(payload)
        .then(response => {
          this.complete = true;
          this.loading = false;
        })
        .catch(response => {
          switch (response.data.status) {
            case -3010:
              this.error = "errors.competitor.startNumberTaken";
              break;
            case -3028:
              this.error = "errors.competitor.alreadyRegistered";
              break;
            default:
              this.error = "errors.unexpectedError";
          }
          this.loading = false;
        });
    },
    setRegistrationFields() {
      this.form.fields = this.event.competitorData.data.map(field => {
        const id = String(field.id).toLowerCase();
        let value = null;

        switch (id) {
          case "nation":
            if (this.user.address.country) value = this.user.address.country;
            break;
          default:
            if (this.user[id]) value = this.user[id];
            break;
        }

        return {
          value,
          ...field
        };
      });
    }
  },
  mounted() {
    if (this.user) {
      this.setRegistrationFields();
    }
  }
};
</script>
