<template>
  <v-form>
    <v-container class="my-4">
      <v-row dense>
        <v-col
          cols="12"
          v-if="
            form.fields[0] &&
              form.fields[0].accessControl.competitor.write &&
              whitelist.includes(form.fields[0].id)
          "
        >
          <v-text-field
            :name="form.fields[0].id"
            v-model.number="form.fields[0].value"
            type="number"
            min="1"
            max="9999"
            :error-messages="appendErrorMessage($v.form.fields.$each.$iter[0])"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.fields[0].id}`) }}
              <span
                v-if="form.fields[0].validation.required"
                class="error--text"
                >*</span
              >
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          v-if="
            form.fields[1] &&
              form.fields[1].accessControl.competitor.write &&
              whitelist.includes(form.fields[1].id)
          "
        >
          <v-text-field
            :name="form.fields[1].id"
            v-model="form.fields[1].value"
            :error-messages="appendErrorMessage($v.form.fields.$each.$iter[1])"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.fields[1].id}`) }}
              <span
                v-if="form.fields[1].validation.required"
                class="error--text"
                >*</span
              >
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          v-if="
            form.fields[2] &&
              form.fields[2].accessControl.competitor.write &&
              whitelist.includes(form.fields[2].id)
          "
        >
          <v-text-field
            :name="form.fields[2].id"
            v-model="form.fields[2].value"
            :error-messages="appendErrorMessage($v.form.fields.$each.$iter[2])"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.fields[2].id}`) }}
              <span
                v-if="form.fields[2].validation.required"
                class="error--text"
                >*</span
              >
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          v-if="
            form.fields[3] &&
              form.fields[3].accessControl.competitor.write &&
              whitelist.includes(form.fields[3].id)
          "
        >
          <v-select
            :name="form.fields[3].id"
            :items="genders"
            v-model="form.fields[3].value"
            :error-messages="appendErrorMessage($v.form.fields.$each.$iter[3])"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.fields[3].id}`) }}
              <span
                v-if="form.fields[3].validation.required"
                class="error--text"
                >*</span
              >
            </template>
          </v-select>
        </v-col>

        <v-col
          cols="12"
          v-if="
            form.fields[4] &&
              form.fields[4].accessControl.competitor.write &&
              whitelist.includes(form.fields[4].id)
          "
        >
          <v-menu
            v-model="showDateOfBirthPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="
                  form.fields[4].value
                    ? $d(new Date(form.fields[4].value).getTime(), 'shortDate')
                    : null
                "
                name="dateOfBirth"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                @click:prepend="showDateOfBirthPicker = true"
                :error-messages="
                  appendErrorMessage($v.form.fields.$each.$iter[4])
                "
              >
                <template v-slot:label>
                  {{ $t(`competitors.tables.${form.fields[4].id}`) }}
                  <span
                    v-if="form.fields[4].validation.required"
                    class="error--text"
                    >*</span
                  >
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              ref="dateOfBirthPicker"
              v-model="form.fields[4].value"
              no-title
              show-current
              first-day-of-week="1"
              :max="new Date().toISOString().substr(0, 10)"
              @input="showDateOfBirthPicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          v-if="
            form.fields[5] &&
              form.fields[5].accessControl.competitor.write &&
              whitelist.includes(form.fields[5].id)
          "
        >
          <v-autocomplete
            :name="form.fields[5].id"
            :items="nations"
            :item-text="getCountryItem"
            item-value="id"
            v-model="form.fields[5].value"
            :error-messages="appendErrorMessage($v.form.fields.$each.$iter[5])"
            auto-select-first
            autocomplete="new-password"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.fields[5].id}`) }}
              <span
                v-if="form.fields[5].validation.required"
                class="error--text"
                >*</span
              >
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          v-if="
            form.fields[6] &&
              form.fields[6].accessControl.competitor.write &&
              whitelist.includes(form.fields[6].id)
          "
        >
          <v-text-field
            :name="form.fields[6].id"
            v-model="form.fields[6].value"
            :error-messages="appendErrorMessage($v.form.fields.$each.$iter[6])"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.fields[6].id}`) }}
              <span
                v-if="form.fields[6].validation.required"
                class="error--text"
                >*</span
              >
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          v-if="
            form.fields[7] &&
              form.fields[7].accessControl.competitor.write &&
              whitelist.includes(form.fields[7].id)
          "
        >
          <v-text-field
            :name="form.fields[7].id"
            v-model="form.fields[7].value"
            :error-messages="appendErrorMessage($v.form.fields.$each.$iter[7])"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.fields[7].id}`) }}
              <span
                v-if="form.fields[7].validation.required"
                class="error--text"
                >*</span
              >
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          v-if="
            form.fields[8] &&
              form.fields[8].accessControl.competitor.write &&
              whitelist.includes(form.fields[8].id)
          "
        >
          <v-text-field
            :name="form.fields[8].id"
            v-model="form.fields[8].value"
            :error-messages="appendErrorMessage($v.form.fields.$each.$iter[8])"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.fields[8].id}`) }}
              <span
                v-if="form.fields[8].validation.required"
                class="error--text"
                >*</span
              >
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          v-if="
            form.fields[9] &&
              form.fields[9].accessControl.competitor.write &&
              whitelist.includes(form.fields[9].id)
          "
        >
          <v-text-field
            :name="form.fields[9].id"
            v-model="form.fields[9].value"
            :error-messages="appendErrorMessage($v.form.fields.$each.$iter[9])"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.fields[9].id}`) }}
              <span
                v-if="form.fields[9].validation.required"
                class="error--text"
                >*</span
              >
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" v-for="(field, index) in customFields" :key="field.id">
          <v-text-field
            :name="field.id"
            v-model="form.fields[index + basicFields.length].value"
            :error-messages="
              appendErrorMessage(
                $v.form.fields.$each.$iter[index + basicFields.length]
              )
            "
          >
            <template v-slot:label>
              {{ field.view.label }}
              <span v-if="field.validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";
import whitelist from "@/mixins/whitelist";

export default {
  name: "FormFields",
  props: {
    wizardData: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        fields: []
      },
      genders: [
        {
          text: this.$i18n.t("forms.general.genders.male"),
          value: "MALE"
        },
        {
          text: this.$i18n.t("forms.general.genders.female"),
          value: "FEMALE"
        }
      ],
      showDateOfBirthPicker: false
    };
  },
  validations: {
    form: {
      fields: {
        $each: {
          value: {
            required: requiredIf(
              field =>
                field.accessControl.competitor.write &&
                field.validation.required
            )
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      nations: state => state.nations.items
    }),
    basicFields() {
      return this.form.fields.filter(el => this.whitelist.includes(el.id));
    },
    customFields() {
      return this.form.fields.slice(
        this.basicFields.length,
        this.form.fields.length
      );
    }
  },
  watch: {
    showDateOfBirthPicker(newShowDateOfBirthPicker) {
      newShowDateOfBirthPicker &&
        setTimeout(() => (this.$refs.dateOfBirthPicker.activePicker = "YEAR"));
    }
  },
  mixins: [whitelist],
  methods: {
    assignData() {
      Object.assign(this.form, this.wizardData);
    },
    submit() {
      this.$v.$touch();
      return new Promise((resolve, reject) => {
        if (!this.$v.$invalid) {
          resolve(this.form);
        } else {
          reject("Field form invalid");
        }
      });
    },
    appendErrorMessage(field) {
      const errors = [];
      if (!field.value.$dirty) return errors;
      !field.value.required &&
        errors.push(
          this.$i18n.t("forms.general.field.errors.required", {
            field: field.$model.view.label
          })
        );
      return errors;
    },
    getCountryItem(item) {
      return this.$i18n.t(`countries.${item.id}`);
    }
  },
  created() {
    this.assignData();
  },
  activated() {
    this.assignData();
  }
};
</script>
