<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card :loading="loading">
          <v-card-title>
            {{ $t("registration.title") }}
          </v-card-title>
          <v-sheet v-if="!loading && event">
            <v-card-text class="body-1 pb-1">
              <span v-if="event.publicResult">
                <router-link
                  :to="{ name: 'Overview', params: { id: event.id } }"
                >
                  {{ event.name }}
                </router-link>
              </span>
              <span v-else>{{ event.name }}</span>
            </v-card-text>
            <v-card-text class="body-2 pt-1">
              {{ $t("registration.openUntil", { date: eventEndDate }) }}
            </v-card-text>

            <v-divider class="mt-4"></v-divider>

            <FormWizard :event="event" :user="user" />
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { mapState, mapActions } from "vuex";
import FormWizard from "@/components/forms/registration/FormWizard";

export default {
  name: "EventRegistration",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    FormWizard
  },
  data() {
    return {
      loading: true
    };
  },
  head() {
    return {
      link: [
        {
          rel: "canonical",
          href: `https://alge-results.com/registration/${this.$route.params.id}`
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      event: state => state.events.selectedOpenItem
    }),
    eventEndDate() {
      return this.$i18n.d(moment(this.event.registration.to), "shortDateTime");
    }
  },
  methods: {
    ...mapActions({
      fetchEvent: "events/fetchSelectedOpenItem",
      createCompetitorForItem: "events/createCompetitorForItem"
    })
  },
  created() {
    this.fetchEvent(this.$route.params.id)
      .then(() => {
        this.form.fields = this.event.competitorData.data.map(field => ({
          value: null,
          ...field
        }));
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }
};
</script>
