<template>
  <v-form @submit.prevent="submitForm" autocomplete="on">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            :label="$t('forms.general.email.label')"
            name="email"
            type="email"
            v-model="form.email"
            :error-messages="emailErrors"
            :success="shouldAppendValidClass($v.form.email)"
            autocomplete="email"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            :label="$t('forms.general.password.label')"
            name="password"
            type="password"
            v-model="form.password"
            :error-messages="passwordErrors"
            :success="shouldAppendValidClass($v.form.password)"
            autocomplete="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-alert v-if="error" class="mt-6" dense outlined type="error">
        {{ $t(error) }}
      </v-alert>
      <v-alert v-else-if="success" class="mt-6" dense outlined type="success">
        {{ $t(success) }}
      </v-alert>
    </v-container>
    <v-card-actions v-if="showButton">
      <v-spacer></v-spacer>
      <v-btn type="submit" color="success">
        {{ $t("forms.login.submit") }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
/* eslint-disable */

import { mapState } from "vuex";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "FormUser",
  props: {
    error: {
      type: String,
      default: null
    },
    success: {
      type: String,
      default: null
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        email: null,
        password: null
      }
    };
  },
  validations: {
    form: {
      email: { required, email },
      password: { required }
    }
  },
  computed: {
    ...mapState({
      nations: state => state.nations.items
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$i18n.t("forms.general.email.errors.required"));
      !this.$v.form.email.email && errors.push(this.$i18n.t("forms.general.email.errors.format"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push(this.$i18n.t("forms.general.password.errors.required"));
      return errors;
    }
  },
  methods: {
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    submit() {
      this.$v.form.$touch();

      return new Promise((resolve, reject) => {
        if (!this.$v.$invalid) {
          resolve(this.form);
        } else {
          reject("login form invalid");
        }
      });
    },
    submitForm() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit("formSubmitted", this.form);
      }
    }
  }
};
</script>
