<template>
  <v-form>
    <v-container class="my-4">
      <p class="headline">
        {{ $t("forms.eventRegistration.review") }}
      </p>

      <v-simple-table>
        <tr>
          <td>{{ $t("forms.general.email.label") }}</td>
          <td>{{ this.user.email }}</td>
        </tr>
        <tr v-for="field in visibleFields" :key="field.id">
          <td>
            {{
              $te(`competitors.tables.${field.id}`)
                ? $t(`competitors.tables.${field.id}`)
                : field.view.label
            }}
          </td>
          <td v-if="field.value && field.id === 'gender'">
            {{ field.value | capitalize }}
          </td>
          <td v-else-if="field.value && field.id === 'dateOfBirth'">
            {{ $d(new Date(field.value).getTime(), "shortDate") }}
          </td>
          <td v-else-if="field.value && field.id === 'nation'">
            {{ $t(`countries.${field.value}`) }}
          </td>
          <td v-else-if="field.value">
            {{ field.value }}
          </td>
          <td v-else>-</td>
        </tr>
      </v-simple-table>

      <v-checkbox
        v-model="form.consent"
        :label="$t('forms.eventRegistration.consent')"
        :error-messages="consentErrors"
      ></v-checkbox>
    </v-container>
  </v-form>
</template>

<script>
import { sameAs } from "vuelidate/lib/validators";

export default {
  name: "FormReview",
  props: {
    wizardData: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        consent: false
      }
    };
  },
  validations: {
    form: {
      consent: {
        sameAs: sameAs(() => true)
      }
    }
  },
  computed: {
    consentErrors() {
      const errors = [];
      if (!this.$v.form.consent.$dirty) return errors;
      !this.$v.form.consent.sameAs &&
        errors.push(
          this.$i18n.t("forms.eventRegistration.errors.consent.required")
        );
      return errors;
    },
    visibleFields() {
      return this.wizardData.fields.filter(
        field => field.accessControl.competitor.write
      );
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  methods: {
    assignData() {
      Object.assign(this.form, this.wizardData);
    },
    submit() {
      this.$v.$touch();
      return new Promise((resolve, reject) => {
        if (!this.$v.$invalid) {
          resolve(this.form);
        } else {
          reject("Review form invalid");
        }
      });
    }
  },
  created() {
    this.assignData();
  },
  activated() {
    this.assignData();
  }
};
</script>
