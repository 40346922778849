var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',[_c('v-container',{staticClass:"my-4"},[_c('v-row',{attrs:{"dense":""}},[(
          _vm.form.fields[0] &&
            _vm.form.fields[0].accessControl.competitor.write &&
            _vm.whitelist.includes(_vm.form.fields[0].id)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":_vm.form.fields[0].id,"type":"number","min":"1","max":"9999","error-messages":_vm.appendErrorMessage(_vm.$v.form.fields.$each.$iter[0])},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`competitors.tables.${_vm.form.fields[0].id}`))+" "),(_vm.form.fields[0].validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,false,3326410131),model:{value:(_vm.form.fields[0].value),callback:function ($$v) {_vm.$set(_vm.form.fields[0], "value", _vm._n($$v))},expression:"form.fields[0].value"}})],1):_vm._e(),(
          _vm.form.fields[1] &&
            _vm.form.fields[1].accessControl.competitor.write &&
            _vm.whitelist.includes(_vm.form.fields[1].id)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":_vm.form.fields[1].id,"error-messages":_vm.appendErrorMessage(_vm.$v.form.fields.$each.$iter[1])},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`competitors.tables.${_vm.form.fields[1].id}`))+" "),(_vm.form.fields[1].validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,false,3519933843),model:{value:(_vm.form.fields[1].value),callback:function ($$v) {_vm.$set(_vm.form.fields[1], "value", $$v)},expression:"form.fields[1].value"}})],1):_vm._e(),(
          _vm.form.fields[2] &&
            _vm.form.fields[2].accessControl.competitor.write &&
            _vm.whitelist.includes(_vm.form.fields[2].id)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":_vm.form.fields[2].id,"error-messages":_vm.appendErrorMessage(_vm.$v.form.fields.$each.$iter[2])},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`competitors.tables.${_vm.form.fields[2].id}`))+" "),(_vm.form.fields[2].validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,false,2917616659),model:{value:(_vm.form.fields[2].value),callback:function ($$v) {_vm.$set(_vm.form.fields[2], "value", $$v)},expression:"form.fields[2].value"}})],1):_vm._e(),(
          _vm.form.fields[3] &&
            _vm.form.fields[3].accessControl.competitor.write &&
            _vm.whitelist.includes(_vm.form.fields[3].id)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"name":_vm.form.fields[3].id,"items":_vm.genders,"error-messages":_vm.appendErrorMessage(_vm.$v.form.fields.$each.$iter[3])},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`competitors.tables.${_vm.form.fields[3].id}`))+" "),(_vm.form.fields[3].validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,false,321837843),model:{value:(_vm.form.fields[3].value),callback:function ($$v) {_vm.$set(_vm.form.fields[3], "value", $$v)},expression:"form.fields[3].value"}})],1):_vm._e(),(
          _vm.form.fields[4] &&
            _vm.form.fields[4].accessControl.competitor.write &&
            _vm.whitelist.includes(_vm.form.fields[4].id)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"value":_vm.form.fields[4].value
                  ? _vm.$d(new Date(_vm.form.fields[4].value).getTime(), 'shortDate')
                  : null,"name":"dateOfBirth","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.appendErrorMessage(_vm.$v.form.fields.$each.$iter[4])},on:{"click:prepend":function($event){_vm.showDateOfBirthPicker = true}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`competitors.tables.${_vm.form.fields[4].id}`))+" "),(_vm.form.fields[4].validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true)},on))]}}],null,false,4281785222),model:{value:(_vm.showDateOfBirthPicker),callback:function ($$v) {_vm.showDateOfBirthPicker=$$v},expression:"showDateOfBirthPicker"}},[_c('v-date-picker',{ref:"dateOfBirthPicker",attrs:{"no-title":"","show-current":"","first-day-of-week":"1","max":new Date().toISOString().substr(0, 10)},on:{"input":function($event){_vm.showDateOfBirthPicker = false}},model:{value:(_vm.form.fields[4].value),callback:function ($$v) {_vm.$set(_vm.form.fields[4], "value", $$v)},expression:"form.fields[4].value"}})],1)],1):_vm._e(),(
          _vm.form.fields[5] &&
            _vm.form.fields[5].accessControl.competitor.write &&
            _vm.whitelist.includes(_vm.form.fields[5].id)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"name":_vm.form.fields[5].id,"items":_vm.nations,"item-text":_vm.getCountryItem,"item-value":"id","error-messages":_vm.appendErrorMessage(_vm.$v.form.fields.$each.$iter[5]),"auto-select-first":"","autocomplete":"new-password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`competitors.tables.${_vm.form.fields[5].id}`))+" "),(_vm.form.fields[5].validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,false,47222931),model:{value:(_vm.form.fields[5].value),callback:function ($$v) {_vm.$set(_vm.form.fields[5], "value", $$v)},expression:"form.fields[5].value"}})],1):_vm._e(),(
          _vm.form.fields[6] &&
            _vm.form.fields[6].accessControl.competitor.write &&
            _vm.whitelist.includes(_vm.form.fields[6].id)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":_vm.form.fields[6].id,"error-messages":_vm.appendErrorMessage(_vm.$v.form.fields.$each.$iter[6])},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`competitors.tables.${_vm.form.fields[6].id}`))+" "),(_vm.form.fields[6].validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,false,3739873043),model:{value:(_vm.form.fields[6].value),callback:function ($$v) {_vm.$set(_vm.form.fields[6], "value", $$v)},expression:"form.fields[6].value"}})],1):_vm._e(),(
          _vm.form.fields[7] &&
            _vm.form.fields[7].accessControl.competitor.write &&
            _vm.whitelist.includes(_vm.form.fields[7].id)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":_vm.form.fields[7].id,"error-messages":_vm.appendErrorMessage(_vm.$v.form.fields.$each.$iter[7])},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`competitors.tables.${_vm.form.fields[7].id}`))+" "),(_vm.form.fields[7].validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,false,1144094227),model:{value:(_vm.form.fields[7].value),callback:function ($$v) {_vm.$set(_vm.form.fields[7], "value", $$v)},expression:"form.fields[7].value"}})],1):_vm._e(),(
          _vm.form.fields[8] &&
            _vm.form.fields[8].accessControl.competitor.write &&
            _vm.whitelist.includes(_vm.form.fields[8].id)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":_vm.form.fields[8].id,"error-messages":_vm.appendErrorMessage(_vm.$v.form.fields.$each.$iter[8])},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`competitors.tables.${_vm.form.fields[8].id}`))+" "),(_vm.form.fields[8].validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,false,484161939),model:{value:(_vm.form.fields[8].value),callback:function ($$v) {_vm.$set(_vm.form.fields[8], "value", $$v)},expression:"form.fields[8].value"}})],1):_vm._e(),(
          _vm.form.fields[9] &&
            _vm.form.fields[9].accessControl.competitor.write &&
            _vm.whitelist.includes(_vm.form.fields[9].id)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":_vm.form.fields[9].id,"error-messages":_vm.appendErrorMessage(_vm.$v.form.fields.$each.$iter[9])},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`competitors.tables.${_vm.form.fields[9].id}`))+" "),(_vm.form.fields[9].validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,false,677685651),model:{value:(_vm.form.fields[9].value),callback:function ($$v) {_vm.$set(_vm.form.fields[9], "value", $$v)},expression:"form.fields[9].value"}})],1):_vm._e(),_vm._l((_vm.customFields),function(field,index){return _c('v-col',{key:field.id,attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":field.id,"error-messages":_vm.appendErrorMessage(
              _vm.$v.form.fields.$each.$iter[index + _vm.basicFields.length]
            )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(field.view.label)+" "),(field.validation.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.form.fields[index + _vm.basicFields.length].value),callback:function ($$v) {_vm.$set(_vm.form.fields[index + _vm.basicFields.length], "value", $$v)},expression:"form.fields[index + basicFields.length].value"}})],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }